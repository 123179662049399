import React from 'react'

const TaIcon = (props) => (
  <svg width="253" height="108" viewBox="0 0 253 108" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6 3.99999C6.1 5.99999 3 9.99999 0 16.1C0.1 15 0.2 7.99999 0.2 6.19999C0.2 4.39999 0.1 3.39999 0 1.89999C1.8 2.09999 4.5 2.19999 6.5 2.19999C7 2.19999 7.1 2.29999 10.6 2.29999V3.99999ZM14.6 2.29999C17.1 2.29999 19.3 2.29999 19.6 2.29999C19.9 2.29999 22 2.29999 24.4 2.19999C24.2 6.39999 24.1 13.5 24.1 23.6C24.1 38 24.7 38.4 29.2 42C29.2 42 23.3 41.8 19.5 41.8C15.7 41.8 10 42 10 42C14.6 38.3 15.1 37.9 15.1 23.1C15 13.2 14.9 6.29999 14.6 2.29999ZM28.4 2.29999C31.5 2.29999 31.6 2.19999 32.1 2.19999C33.4 2.19999 36.4 2.09999 38.6 1.89999C38.5 3.39999 38.4 4.39999 38.4 6.19999C38.4 7.99999 38.5 14.9 38.6 16.1C35.3 9.79999 33.1 6.19999 28.4 3.99999V2.29999Z" fill={props.fill}/>
    <path d="M52.2 41.3L52.5 42.8C43 42.9 37.7 37.9 37.7 29.2C37.7 21.2 42.3 15.2 49.7 13.4L50.1 14.9C48.2 16.3 47.1 21 47.1 27.5C47.1 35.4 49 40.5 52.2 41.3ZM57.1 42.4L56.7 40.8C58.4 39.7 59.3 35.6 59.3 29.2C59.3 20.6 57.6 15.4 54.4 14.6L54.1 13C63.3 12.9 68.7 18.3 68.7 27.3C68.9 35.2 64.5 40.8 57.1 42.4Z" fill={props.fill}/>
    <path d="M74.3 40.3C74.3 37.8 77.9 35.9 83.6 35.3C83.1 35.6 82.9 35.9 82.9 36.4C82.9 37.1 83.6 37.5 85.2 37.6L95.1 38.5C99.7 39 102.4 41.7 102.4 45.8C102.4 51.9 95 56.2 84.5 56.2C76.8 56.2 71 53.7 71 50.3C71 48 73.3 46.7 78.6 46C78 46.8 77.7 47.5 77.7 48.4C77.7 51.7 80.6 53.3 86.3 53.3C91.2 53.3 95 51.1 95 48.4C95 46.5 93.2 45.4 89.5 45.1L79.8 44.2C76.4 44 74.3 42.5 74.3 40.3ZM81.9 13.2L82.2 14.7C81 15.8 80.6 18.8 80.6 23.1C80.6 28.6 81.7 31.2 83 32.4L83.2 33.9C76.8 33.6 71.9 30.6 71.9 24.2C72 18.7 75.8 14.3 81.9 13.2ZM87.8 33.8L87.5 32.2C88.6 31.1 89.4 29.3 89.4 23.8C89.4 18.6 88.1 15.5 86.7 14.5L86.5 13C89.5 13.2 91.9 14.1 93.8 15.5L103.3 13.5V20L95.5 16.9C97.3 18.9 98.2 21.4 98.2 23.7C98 27.9 95 32.7 87.8 33.8Z" fill={props.fill}/>
    <path d="M115.5 13.5L115.8 15C114.2 16.4 113.5 20.3 113.5 24.5C113.5 33.2 116.6 36.2 122.8 36.2C126.2 36.2 129.5 35.3 132.2 33.7C130.8 38.9 125.3 42.8 119.2 42.8C110 42.8 104.1 37.3 104.1 28.6C104 21.1 108.5 15.1 115.5 13.5ZM117.2 27.2V25.6L123.3 25.3C123.3 24.8 123.3 22.4 123.2 21.5C123 18.3 122 15.2 120.2 14.5L120 13C127.8 13.1 132.4 18.3 132.4 25.7C132.4 26.4 132.3 26.6 132.3 27L117.2 27.2Z" fill={props.fill}/>
    <path d="M156.8 35.8C155.2 40.6 150.3 42.8 146.1 42.8C141.1 42.8 137.8 40.6 137.8 34.1C137.8 33.2 137.9 23.4 137.9 17H133.7L146.8 5.89999L146.5 14.8H157.3L157.1 17.4H146.5V31.8C146.5 35.5 147.8 37.6 151.1 37.6C154.2 37.5 156.8 35.8 156.8 35.8Z" fill={props.fill}/>
    <path d="M170.1 28.1C170.1 37.9 170.2 39 174.9 42.1C174.9 42.1 169.8 41.9 165.7 41.9C162.2 41.9 157.3 42.1 157.3 42.1C161.4 38.5 161.5 37.6 161.5 23.4C161.5 7.2 161.5 6.7 156.9 3.5L170.4 0C170.3 1.2 170.1 18.3 170.1 19.5V28.1ZM183.1 12.9C187.7 12.9 190.5 16.8 190.5 23.4C190.4 37.2 190.6 39 194.6 42.1C194.6 42.1 187.8 41.9 184.5 41.9C181.2 41.9 177.3 42.1 177.3 42.1C180.7 38.8 181.8 38.2 181.8 30.7C181.8 21.5 180.1 18.9 175.7 18.9C175.1 18.9 174.4 19 173.6 19.2C176.1 15.1 179.3 12.9 183.1 12.9Z" fill={props.fill}/>
    <path d="M207.6 13.5L207.9 15C206.3 16.4 205.6 20.3 205.6 24.5C205.6 33.2 208.7 36.2 214.9 36.2C218.3 36.2 221.6 35.3 224.3 33.7C222.9 38.9 217.4 42.8 211.3 42.8C202.1 42.8 196.2 37.3 196.2 28.6C196.1 21.1 200.6 15.1 207.6 13.5ZM209.3 27.2V25.6L215.4 25.3C215.4 24.8 215.4 22.4 215.3 21.5C215.1 18.3 214.1 15.2 212.3 14.5L212.1 13C219.9 13.1 224.5 18.3 224.5 25.7C224.5 26.4 224.4 26.6 224.4 27L209.3 27.2Z" fill={props.fill}/>
    <path d="M239.5 28.1C239.5 37.9 240.1 39 246 42.1C246 42.1 238.9 41.9 235.1 41.9C231.3 41.9 226.7 42.1 226.7 42.1C230.5 38.6 230.9 37.4 230.9 29.8C230.9 20.7 230.3 19.5 226.3 16.4L239.6 12.9L239.5 20.3V28.1ZM252.2 17.5C252.2 20.2 250.8 22.2 248.2 22.2C245.7 22.2 245.5 20.5 243.3 20.5C242.8 20.5 242.4 20.6 242.2 20.7C242.8 15.7 244.7 12.9 248 12.9C250.5 12.9 252.2 14.5 252.2 17.5Z" fill={props.fill}/>
    <path d="M109 64L104.9 78.1C102 88.3 102.2 90.5 108.3 94.1C108.3 94.1 102.4 93.8 100.3 93.8C98.1 93.8 93.5 94.1 93.5 94.1C97.3 90.2 98.1 87.5 101.1 79.9C104.6 71 106.2 67.1 108.1 62.1L109 64ZM109.2 79.2H118.5C118.3 78.8 118.2 78.4 118 78L110.3 56.8L111.3 54.3C112.4 54.1 117.9 54.1 119 54.3L129.4 82.6C131.2 87.6 132 90 135.2 94.1C135.2 94.1 128.6 93.8 126.4 93.8C124.2 93.8 117.8 94.1 117.8 94.1C121.7 90.8 121.5 89 120.8 86.4C120.6 85.7 120.3 84.6 119.3 81.4H108.4L109.2 79.2Z" fill={props.fill}/>
    <path d="M147.5 92.4C147.5 104.2 148.5 104.6 154 107.4C154 107.4 147 107.2 142.9 107.2C139.4 107.2 134.7 107.4 134.7 107.4C138.8 103.8 138.9 103.3 138.9 88.2C138.9 73.1 138.8 71.9 134.2 69L147.5 65.2L147.4 71.3V80.4C147.5 83.2 147.5 87.6 147.5 92.4ZM152.8 94.7H152.1L152.2 93.1C152.4 93.2 152.8 93.2 153 93.2C157.3 93.2 159.9 88.2 159.9 80.3C159.9 73.6 158.1 70.6 153.2 70.6C152.7 70.6 152.3 70.6 151.3 70.8C153.1 67.1 156.2 65 159.5 65C165.4 64.9 169.4 70.7 169.4 78.8C169.3 88.7 163.1 94.7 152.8 94.7Z" fill={props.fill}/>
    <path d="M180.3 85.9C180.3 88.2 181.3 89.6 183.5 89.6C184 89.6 184.5 89.5 185 89.3C184.4 93 181.7 94.8 178.4 94.8C174.2 94.8 171.8 92.1 171.8 88.2C171.8 83.2 176.5 80.3 184.9 80.1C181.7 81.1 180.3 83.4 180.3 85.9ZM187.5 82.6C187.6 80.5 187.8 78.3 187.8 76.9C187.8 73.2 186.5 71 182 71C179.5 71 176.8 71.7 173.8 73C174.9 68.3 179.9 64.9 185.8 64.9C192.2 64.9 196.4 68.7 196.4 74.8C196.4 76.7 196 78.9 196 83C196 88.7 196.7 90 200.7 91.5C199 93.3 195.7 94.8 193.1 94.8C189.4 94.8 187.4 92.1 187.4 87.2C187.4 86.4 187.5 83.2 187.5 82.6Z" fill={props.fill}/>
    <path d="M213.6 80.1C213.6 89.9 214.2 91 220.1 94.1C220.1 94.1 213 93.9 209.2 93.9C205.4 93.9 200.8 94.1 200.8 94.1C204.6 90.6 205 89.4 205 81.8C205 72.7 204.4 71.5 200.4 68.4L213.7 64.9L213.6 72.3V80.1ZM226.4 69.5C226.4 72.2 225 74.2 222.4 74.2C219.9 74.2 219.7 72.5 217.5 72.5C217 72.5 216.6 72.6 216.4 72.7C217 67.7 218.9 64.9 222.2 64.9C224.6 64.9 226.4 66.5 226.4 69.5Z" fill={props.fill}/>
    <path d="M250.2 87.8C248.6 92.6 243.7 94.8 239.5 94.8C234.5 94.8 231.2 92.6 231.2 86.1C231.2 85.2 231.3 75.4 231.3 69H227.1L240.2 57.9L239.9 66.8H250.8L250.6 69.4H240V83.8C240 87.5 241.3 89.6 244.6 89.6C247.7 89.5 250.2 87.8 250.2 87.8Z" fill={props.fill}/>
  </svg>
)

export default TaIcon