import React from 'react'
import Nav from './nav'

import '../styles/layout.scss'
import styles from './layout.module.scss'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, siteLogo, about}) => (
  <>
    <Nav siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} siteLogo={siteLogo} about={about}/>
    <div className={styles.content}>{children}</div>
  </>
)

export default Layout
