import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const ArrowIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.16 0.16C20.68 3.73333 20.44 7.17333 20.44 10.48C20.44 13.7867 20.68 16.7733 21.16 19.44L16.24 19.72C16.08 18.28 16 16.8667 16 15.48C16 12.68 16.28 9.98667 16.84 7.4C15.32 9.29333 13.5333 11.24 11.48 13.24L3.56 21.16L0 17.64L7.92 9.68C9.81333 7.73333 11.7733 5.94667 13.8 4.32C11.16 4.88 8.4 5.16 5.52 5.16C4.32 5.16 2.96 5.09333 1.44 4.96L1.72 0C4.41333 0.48 7.44 0.72 10.8 0.72C14.0533 0.72 17.4533 0.48 21 0L21.16 0.16Z" fill="black"/>
  </svg>
)

export default ArrowIcon
