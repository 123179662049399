import React from 'react'
import ArrowIcon from './arrow'
import HamburgerIcon from './hamburger'
import SosoIcon from './soso'
import Tagline from './tagline'
import TaIcon from './ta'
import TwitterIcon from './twitter'


function Icon (props) {
  switch (props.symbol) {
    case 'arrow':
      return <ArrowIcon />
    case 'hamburger':
      return <HamburgerIcon />
    case 'soso':
      return <SosoIcon />
    case 'tagline':
      return <Tagline />
    case 'ta': 
      return <TaIcon fill={props.fill}/>
    case 'twitter': 
      return <TwitterIcon />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
