import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const SosoIcon = () => (
  <svg width="325" height="100" viewBox="0 0 325 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M111.873 100C101.948 100 92.245 97.0665 83.9926 91.5703C75.7402 86.0742 69.3086 78.2624 65.5113 69.123C61.7139 59.9836 60.7214 49.9271 62.6593 40.2255C64.5972 30.5238 69.3784 21.6129 76.3981 14.6196C83.4179 7.62638 92.361 2.86499 102.096 0.937677C111.831 -0.98964 121.922 0.00369325 131.09 3.79204C140.259 7.58039 148.094 13.9936 153.606 22.2205C159.117 30.4474 162.056 40.1184 162.052 50.0104C162.036 63.267 156.743 75.9756 147.336 85.3475C137.928 94.7194 125.174 99.989 111.873 100ZM111.873 7.03468C103.344 7.03468 95.0075 9.55516 87.9162 14.2774C80.825 18.9996 75.2981 25.7115 72.0344 33.5643C68.7707 41.4171 67.9167 50.0581 69.5806 58.3946C71.2444 66.731 75.3513 74.3886 81.3818 80.3988C87.4124 86.4091 95.0958 90.5021 103.46 92.1604C111.825 93.8186 120.495 92.9675 128.375 89.7148C136.254 86.4621 142.989 80.9538 147.727 73.8864C152.465 66.8191 154.994 58.5102 154.994 50.0104C154.983 38.6159 150.436 27.6912 142.352 19.6341C134.268 11.577 123.306 7.04567 111.873 7.03468Z" fill="black"/>
    <path d="M111.873 86.7193C104.583 86.7192 97.457 84.5646 91.3959 80.5277C85.3349 76.4909 80.6113 70.7533 77.8225 64.0407C75.0338 57.328 74.3052 49.9418 75.7289 42.8164C77.1526 35.691 80.6646 29.1464 85.8208 24.0105C90.977 18.8746 97.5456 15.3781 104.696 13.9632C111.846 12.5484 119.257 13.2787 125.991 16.0619C132.724 18.845 138.479 23.556 142.526 29.5989C146.573 35.6417 148.731 42.7451 148.727 50.0104C148.71 59.7447 144.821 69.0753 137.913 75.9566C131.005 82.8379 121.64 86.7083 111.873 86.7193ZM111.873 20.3154C105.976 20.299 100.208 22.0266 95.2969 25.2795C90.3862 28.5325 86.5543 33.1644 84.2864 38.589C82.0185 44.0136 81.4165 49.9868 82.5567 55.7525C83.697 61.5183 86.5281 66.8172 90.6917 70.9784C94.8553 75.1396 100.164 77.976 105.946 79.1285C111.728 80.2811 117.723 79.6979 123.173 77.4528C128.622 75.2077 133.28 71.4017 136.558 66.5166C139.835 61.6315 141.585 55.887 141.585 50.0104C141.58 42.1508 138.45 34.6135 132.882 29.0482C127.313 23.4829 119.759 20.3429 111.873 20.3154Z" fill="black"/>
    <path d="M274.841 100C264.916 100 255.214 97.0665 246.961 91.5703C238.709 86.0742 232.277 78.2624 228.48 69.123C224.682 59.9836 223.69 49.9271 225.628 40.2255C227.566 30.5238 232.347 21.6129 239.367 14.6196C246.386 7.62638 255.329 2.86499 265.065 0.937677C274.8 -0.98964 284.89 0.00369325 294.059 3.79204C303.227 7.58039 311.063 13.9936 316.574 22.2205C322.085 30.4474 325.025 40.1184 325.021 50.0104C325.004 63.267 319.712 75.9756 310.304 85.3475C300.897 94.7194 288.143 99.989 274.841 100ZM274.841 7.03468C266.312 7.03057 257.973 9.54756 250.879 14.2673C243.785 18.987 238.255 25.6975 234.988 33.5499C231.721 41.4023 230.864 50.044 232.526 58.3818C234.187 66.7197 238.292 74.3791 244.322 80.3915C250.352 86.4038 258.035 90.4989 266.4 92.1588C274.766 93.8187 283.437 92.9689 291.317 89.7168C299.198 86.4647 305.934 80.9564 310.673 73.8886C315.412 66.8208 317.942 58.5111 317.942 50.0104C317.931 38.6195 313.387 27.698 305.307 19.6414C297.227 11.5849 286.271 7.05116 274.841 7.03468Z" fill="black"/>
    <path d="M274.841 86.7193C267.552 86.7192 260.425 84.5646 254.364 80.5277C248.303 76.4909 243.58 70.7533 240.791 64.0407C238.002 57.328 237.274 49.9418 238.697 42.8164C240.121 35.691 243.633 29.1464 248.789 24.0105C253.945 18.8746 260.514 15.3781 267.664 13.9632C274.815 12.5484 282.225 13.2787 288.959 16.0619C295.693 18.845 301.447 23.556 305.494 29.5989C309.541 35.6417 311.699 42.7451 311.695 50.0104C311.679 59.7447 307.79 69.0753 300.881 75.9566C293.973 82.8379 284.609 86.7083 274.841 86.7193ZM274.841 20.3154C268.948 20.3113 263.185 22.0494 258.282 25.3098C253.38 28.5703 249.558 33.2066 247.299 38.6322C245.041 44.0579 244.448 50.0292 245.595 55.7909C246.743 61.5525 249.579 66.8456 253.745 71.0005C257.911 75.1555 263.22 77.9858 269 79.1332C274.78 80.2807 280.772 79.6939 286.218 77.4469C291.664 75.2 296.318 71.3939 299.593 66.5102C302.868 61.6264 304.616 55.8844 304.616 50.0104C304.605 42.1418 301.465 34.5982 295.884 29.0323C290.303 23.4664 282.737 20.3319 274.841 20.3154Z" fill="black"/>
    <path d="M30.1701 86.7195C25.7044 86.714 21.4231 84.9435 18.2654 81.7964C15.1076 78.6493 13.3312 74.3825 13.3256 69.9318H20.3841C20.3896 72.5168 21.4224 74.9943 23.2564 76.8222C25.0905 78.65 27.5764 79.6794 30.1701 79.6848C35.0423 79.6848 39.9769 76.8419 39.9769 71.3844C39.9769 65.491 33.7305 62.1293 28.3586 59.8674C27.6924 59.5769 26.9011 59.2864 26.0058 58.9336C17.5524 55.6549 0 48.8278 0 30.0687C0 22.0886 3.18082 14.4353 8.84271 8.79243C14.5046 3.1496 22.1838 -0.0205078 30.1909 -0.0205078C38.198 -0.0205078 45.8772 3.1496 51.5391 8.79243C57.201 14.4353 60.3818 22.0886 60.3818 30.0687H53.3026C53.1221 24.0767 50.607 18.3904 46.2904 14.2156C41.9739 10.0407 36.1955 7.70574 30.1805 7.70574C24.1655 7.70574 18.3871 10.0407 14.0706 14.2156C9.75404 18.3904 7.23887 24.0767 7.05843 30.0687C7.05843 44.0135 20.509 49.2636 28.5669 52.3763L31.0862 53.3931C35.0423 55.0324 47.0354 60.0542 47.0354 71.3844C47.0354 80.1206 39.7895 86.7195 30.1701 86.7195Z" fill="black"/>
    <path d="M30.1909 100C22.1891 99.9945 14.5163 96.8255 8.85615 91.1883C3.19605 85.5512 0.0110275 77.9064 0 69.9315H7.05843C6.96555 73.0159 7.4949 76.0875 8.6151 78.9642C9.73531 81.841 11.4236 84.4643 13.5798 86.6788C15.7361 88.8933 18.3165 90.6538 21.168 91.856C24.0196 93.0582 27.0843 93.6777 30.1805 93.6777C33.2767 93.6777 36.3414 93.0582 39.193 91.856C42.0445 90.6538 44.6249 88.8933 46.7812 86.6788C48.9374 84.4643 50.6257 81.841 51.7459 78.9642C52.8661 76.0875 53.3955 73.0159 53.3026 69.9315C53.3026 56.0282 39.852 50.7574 31.7942 47.7277L29.2748 46.7317C25.3187 45.0716 13.3256 40.0498 13.3256 28.7197C13.3256 19.9834 20.5715 13.3845 30.1909 13.3845C34.6603 13.39 38.9447 15.1633 42.103 18.3149C45.2614 21.4665 47.0354 25.7387 47.0354 30.193H39.9769C39.9769 27.6044 38.9466 25.1215 37.1119 23.2892C35.2773 21.4568 32.7882 20.4247 30.1909 20.4192C25.3187 20.4192 20.3841 23.2621 20.3841 28.7197C20.3841 34.6337 26.6305 37.9747 32.0024 40.2366C32.6687 40.5271 33.4599 40.8176 34.3552 41.1704C42.8086 44.3453 60.3818 51.1724 60.3818 69.9315C60.3708 77.9064 57.1858 85.5512 51.5257 91.1883C45.8656 96.8255 38.1927 99.9945 30.1909 100Z" fill="black"/>
    <path d="M193.159 100C185.161 99.989 177.494 96.8176 171.838 91.181C166.182 85.5445 163 77.9028 162.989 69.9315H170.027C170.027 76.046 172.464 81.91 176.802 86.2336C181.14 90.5571 187.024 92.9861 193.159 92.9861C199.295 92.9861 205.178 90.5571 209.517 86.2336C213.855 81.91 216.292 76.046 216.292 69.9315C216.292 55.9867 202.82 50.7574 194.783 47.624L192.264 46.6279C188.308 44.9678 176.315 39.946 176.315 28.6159C176.315 19.8796 183.561 13.2808 193.159 13.2808C195.374 13.2808 197.567 13.7155 199.613 14.5602C201.66 15.4049 203.519 16.643 205.085 18.2038C206.651 19.7647 207.893 21.6176 208.741 23.6569C209.588 25.6962 210.025 27.8819 210.025 30.0892H202.966C202.966 28.8057 202.713 27.5348 202.22 26.349C201.727 25.1631 201.005 24.0857 200.094 23.1781C199.183 22.2705 198.102 21.5506 196.912 21.0594C195.722 20.5682 194.447 20.3154 193.159 20.3154C188.287 20.3154 183.373 23.1583 183.373 28.6159C183.373 34.53 189.62 37.8709 194.992 40.1328C195.658 40.4233 196.428 40.7138 197.344 41.0666C205.673 44.3453 223.35 51.1724 223.35 69.9315C223.339 77.9064 220.154 85.5512 214.494 91.1883C208.834 96.8255 201.161 99.9945 193.159 100Z" fill="black"/>
    <path d="M193.159 86.7192C188.694 86.7138 184.412 84.9433 181.255 81.7962C178.097 78.649 176.32 74.3822 176.315 69.9315H183.373C183.379 72.5165 184.412 74.9941 186.246 76.8219C188.08 78.6498 190.566 79.6791 193.159 79.6846C198.032 79.6846 202.966 76.8417 202.966 71.3841C202.966 65.4908 196.72 62.1291 191.348 59.8672C190.682 59.5767 189.89 59.2862 188.995 58.9334C180.542 55.6547 162.989 48.8276 162.989 30.0685C162.989 22.0883 166.17 14.435 171.832 8.79218C177.494 3.14936 185.173 -0.020752 193.18 -0.020752C201.187 -0.020752 208.867 3.14936 214.528 8.79218C220.19 14.435 223.371 22.0883 223.371 30.0685H216.313C216.313 23.954 213.876 18.09 209.537 13.7664C205.199 9.44286 199.315 7.0139 193.18 7.0139C187.045 7.0139 181.161 9.44286 176.823 13.7664C172.485 18.09 170.048 23.954 170.048 30.0685C170.048 44.0133 183.519 49.2633 191.577 52.376L194.096 53.3928C198.032 55.0322 210.045 60.054 210.045 71.3841C210.045 80.1204 202.8 86.7192 193.18 86.7192H193.159Z" fill="black"/>
  </svg>
)

export default SosoIcon
