import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const TwitterIcon = () => (
  <svg width="58" height="47" viewBox="0 0 58 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M57.8864 5.59702C55.7478 6.53427 53.4641 7.17276 51.0596 7.46429C53.51 5.99216 55.3949 3.67191 56.2867 0.903522C53.9885 2.24073 51.4414 3.21413 48.7301 3.75624C46.5648 1.44563 43.4789 0 40.0522 0C33.4864 0 28.1627 5.30789 28.1627 11.847C28.1627 12.7866 28.2714 13.6902 28.4696 14.5551C18.5858 14.0901 9.82576 9.35808 3.96317 2.20459C2.93129 3.94417 2.35374 5.96565 2.35374 8.16784C2.35374 12.2879 4.45615 15.9092 7.64118 18.0367C5.69101 17.9741 3.85684 17.4392 2.25707 16.5525V16.6995C2.25707 22.4459 6.34832 27.2382 11.7928 28.3296C10.7948 28.5971 9.74118 28.7416 8.66097 28.7416C7.90217 28.7416 7.17478 28.6693 6.4474 28.5344C7.97225 33.24 12.3559 36.6709 17.5733 36.7673C13.5134 39.9453 8.36857 41.8391 2.82738 41.8391C1.88492 41.8391 0.944877 41.7837 0 41.6777C5.28986 45.0363 11.5222 47 18.262 47C40.1416 47 52.0915 28.9392 52.0915 13.3022C52.0915 12.7987 52.0915 12.2903 52.0553 11.7843C54.3776 10.1243 56.4051 8.02568 58 5.6452L57.8864 5.59702Z" fill="white"/>
  </svg>
)

export default TwitterIcon
