import {cn, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import React, {useState, useEffect} from 'react'
import { Link } from "gatsby"

import Icon from './icon/index'
import BlockText from './block-text'
import styles from './nav.module.scss'


const Nav = ({onHideNav, onShowNav, showNav, siteTitle, siteLogo, about}) => {
  var timer
  const [open, setOpen] = useState(false)
  const [pos, setPos] = useState(0);
  const [navText, setNavText] = useState('About')
  const [aboutHeight, setAboutHeight] = useState('80%')

  const drawerStyle = {
    top: open ? '60px' : '-100vh',
    height: aboutHeight,
    opacity: open ? .99 : 0,
    transition: 'top 750ms, opacity 750ms'
  }

  function handleChange () {
    if (open) {
      document.body.classList.remove(styles.fixed)
      setPos(0)
      setNavText('About')
      setOpen(false)
    }
    else {
      document.body.classList.add(styles.fixed)
      setPos(1)
      setNavText('Close')
      setOpen(true)
    }
  }

  useEffect(() => {
    if (window.innerWidth >= 1440) {
      setAboutHeight(document.getElementById('caro').clientHeight)
    }
    else {
      setAboutHeight(window.innerHeight - 60)
    }  

    window.addEventListener('resize', () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let width = window.innerWidth
        let height = window.innerHeight

        if (width >= 1440) {
          setAboutHeight(document.getElementById('caro').clientHeight)
        }
        else {
          setAboutHeight(height - 60)
        }    
      }, 500)
    })
  })

  return(
    <div className={styles.root}>
      <div className={styles.navbar}>
        <div className={styles.branding}>
          {siteLogo && siteLogo.asset && (
            <Link to="/">
              <img
                className={styles.logo}
                src={imageUrlFor(buildImageObj(siteLogo)).url()}
                alt={siteLogo.alt}
              />
            </Link>
          )}
        </div>
        <h3 onClick={() => handleChange()}>{navText}</h3>
      </div>
      
      <div className={styles.drawer} style={drawerStyle}>
        <div className={styles.content}>
          
          <h1>Building Connections</h1>
          <BlockText blocks={about}></BlockText>
          <a href='https://link.medium.com/bVw9fY18q6' target='_blank' className={styles.cta}>
            <h3>Read full mission statement </h3>
            <Icon symbol='arrow' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Nav
